import { Checkbox, FormControlLabel, makeStyles, Theme } from '@material-ui/core';
import React, { FC } from 'react';
import clsx from 'clsx';

interface RetailGreenCheckboxProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  checked: boolean;
}

const useCheckboxStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 4,
    border: 'solid 1px #93979b',
    width: 18,
    height: 18,
    backgroundColor: '#fff',
  },
  checkedIcon: {
    border: `solid 1px ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
}));

function StyledCheckbox(props) {
  const classes = useCheckboxStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
}

const useStyles = makeStyles(() => {
  return {
    title: {
      marginLeft: -8,
      fontSize: 24,
      paddingTop: 20,
      '& > span': {
        fontSize: 16,
      },
    },
  };
});

const RetailGreenCheckbox: FC<RetailGreenCheckboxProps> = ({ onChange, label, checked }) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.title}
      control={<StyledCheckbox checked={checked} onChange={onChange} />}
      label={label}
    />
  );
};

export default RetailGreenCheckbox;

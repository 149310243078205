import React from 'react';
import { BaseTextFieldProps, makeStyles, MenuItem, TextField, Theme } from '@material-ui/core';
import { ArrowDownIcon } from 'assets/svgs';

interface RetailSelectProps extends BaseTextFieldProps {
  icon?: boolean;
  spaces?: boolean;
  label: React.ReactNode;
  placeholder?: string;
  value: any;
  onChange: (value: any) => void;
  onOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      flexGrow: 1,
      backgroundColor: 'white',
    },
    placeholder: {
      color: '#abafb4',
    },
    none: {
      display: 'none',
    },
    iconFlex: {
      display: 'flex',
    },
    highlighted: {
      border: `solid 1px ${theme.palette.primary.main} !important`,
      borderRadius: 6,
      transition: 'all 0.4s',
    },
  };
});

const RetailSelect: React.FC<RetailSelectProps> = (props) => {
  const classes = useStyles();
  const {
    label,
    value,
    placeholder,
    onChange,
    onOpen,
    children,
    icon,
    spaces,
    disabled,
    error,
    required,
    id,
  } = props;

  return (
    <TextField
      margin={spaces ? 'dense' : 'none'}
      select
      fullWidth
      variant="filled"
      disabled={disabled}
      required={required}
      error={error}
      InputProps={{
        disableUnderline: true,
        classes: {
          focused: classes.highlighted,
        },
        id,
      }}
      SelectProps={{
        onOpen,
        IconComponent: ArrowDownIcon,
        displayEmpty: !!placeholder,
        MenuProps: {
          disableScrollLock: true,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        },
        classes: { select: icon ? classes.iconFlex : '' },
      }}
      className={classes.root}
      InputLabelProps={{ shrink: true }}
      label={label}
      value={value}
      onChange={(ev) => onChange(ev.target.value)}
    >
      {placeholder && (
        <MenuItem value="" disabled className={classes.none}>
          <div className={classes.placeholder}>{placeholder}</div>
        </MenuItem>
      )}
      {children}
    </TextField>
  );
};

export default RetailSelect;

import React, { ChangeEvent, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import RetailTextInput from 'components/retail/structures/RetailTextInput';

export interface AutocompleteOption {
  alias: string;
  name: string;
  value: any;
}

interface RetailAutocompleteProps<T = any> {
  id?: string;
  className?: string;
  label: string;
  placeholder?: string;
  value: T;
  inputValue?: any;
  freeSolo?: boolean;
  required?: boolean;
  disabled?: boolean;
  newValue?: boolean;
  newValuePrefix?: string;
  spaces?: boolean;
  optionLabel: (option: T) => string;
  optionSelected: (option: T, value: T) => boolean;
  onChange: (value: T) => void;
  onSearch: (value: string) => Promise<T[]>;
  onNewValue?: (value: string) => T;
  errorMessage?: string;
  filterOptions?: any;
  disableClearable?: boolean;
}

const useStyles = makeStyles(
  () => {
    return {
      root: {
        flexGrow: 1,
      },
      inputRoot: {
        '&$inputRoot': {
          backgroundColor: 'white',
          paddingTop: 32,
          paddingLeft: 16,
          paddingBottom: '10px !important',
          paddingRight: 39,
          '&:placeholder': {
            color: 'green',
          },
          '& input': {
            padding: '0 !important',
            '&:disabled': {
              color: '#ABAFB5',
            },
            '&:placeholder': {
              color: 'red',
            },
          },
        },
      },
      option: {
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        minHeight: 'auto',
      },
      input: {
        '&::placeholder': {
          color: '#abafb4',
        },
      },
      endAdornment: {
        right: 9,
      },
    };
  },
  { name: 'RetailAutocomplete' }
);

const useRetailAutocomplete = (props: PropsWithChildren<RetailAutocompleteProps>) => {
  const { onSearch, value, onChange, freeSolo, onNewValue, optionLabel, errorMessage } = props;
  const classes = useStyles();
  const [timerTyping, setTimerTyping] = useState<any>(0);
  const [doneTimer] = useState(100);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<AutocompleteOption[]>([]);

  const handleOptionLabel = useCallback(
    (option: any) => {
      if (!option) {
        return '';
      }
      if (typeof option === 'string') {
        return option;
      }
      if (option.inputValue) {
        return option.inputValue;
      }
      return optionLabel(option);
    },
    [optionLabel]
  );

  useEffect(() => {
    if (!open) {
      return;
    }
    setLoading(true);
    onSearch(handleOptionLabel(value)).then((res) => {
      setOptions(res);
      setLoading(false);
    });
  }, [value, open, handleOptionLabel, onSearch]);

  const handleChange = (ev: ChangeEvent<any>, value: any) => {
    if (freeSolo && onNewValue) {
      if (typeof value === 'string') {
        value = onNewValue(value);
      }
    }
    onChange(value);
  };

  const inputChange = (value: string) => {
    if (value.length < 256) {
      clearTimeout(timerTyping);
      setTimerTyping(
        setTimeout(() => {
          setLoading(true);
          onSearch(value)
            .then((res) => {
              setOptions(res);
            })
            .finally(() => setLoading(false));
        }, doneTimer)
      );
    }
  };

  return {
    ...props,
    classes,
    open,
    value,
    loading,
    setOpen,
    options,
    handleChange,
    inputChange,
    handleOptionLabel,
    errorMessage,
  };
};

const RetailAutocomplete: React.FC<RetailAutocompleteProps> = (props) => {
  const {
    classes,
    className,
    label,
    placeholder,
    value,
    open,
    options,
    required,
    disabled,
    spaces,
    loading,
    setOpen,
    freeSolo,
    optionSelected,
    handleChange,
    inputChange,
    handleOptionLabel,
    errorMessage,
    filterOptions,
    disableClearable = false,
  } = useRetailAutocomplete(props);

  return (
    <div className={clsx(classes.root, className)}>
      <Autocomplete
        value={disabled ? '' : value}
        disabled={disabled}
        classes={classes}
        freeSolo={freeSolo}
        open={open}
        loading={loading}
        options={options}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        getOptionSelected={optionSelected}
        disableClearable={!!errorMessage || disableClearable}
        getOptionLabel={handleOptionLabel}
        onChange={handleChange}
        openOnFocus
        renderInput={(props) => (
          <RetailTextInput
            {...props}
            label={label}
            required={required}
            placeholder={placeholder}
            disabled={disabled}
            spaces={spaces}
            onChange={inputChange}
            errorMessage={errorMessage}
            inputProps={{
              ...props.inputProps,
              autoComplete: 'new-password',
            }}
          />
        )}
        filterOptions={filterOptions}
      />
    </div>
  );
};

export default RetailAutocomplete;

import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorIcon } from 'assets/svgs';
import { Hidden, Theme, Tooltip, TooltipProps } from '@material-ui/core';

const useTooltipStyles = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    maxWidth: 260,
    fontSize: 12,
    fontWeight: 400,
    padding: theme.spacing(0.75, 1),
  },
}));

const StyledTooltip: React.FC<TooltipProps> = (props) => {
  const classes = useTooltipStyles();
  return <Tooltip arrow classes={classes} {...props} />;
};

interface RetailErrorTooltipProps {
  className?: string;
  title: string;
}

const useStyles = makeStyles(() => {
  return {
    svg: {
      pointerEvents: 'auto',
      height: 19,
      fill: '#ABAEB5',
    },
  };
});

const RetailErrorTooltip: React.FC<RetailErrorTooltipProps> = ({ title }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const toggleTooltip = () => setOpen(!open);
  const spanRef = useRef(null);
  const svgRef = useRef(null);

  const onClickAway = (event) => {
    if (open && spanRef && spanRef.current && !spanRef.current.contains(event.target)) {
      toggleTooltip();
    }
  };

  useEffect(() => {
    document.addEventListener('click', onClickAway);
    return () => document.removeEventListener('click', onClickAway);
  });

  return (
    <>
      <Hidden smUp>
        <StyledTooltip placement="top" title={title} open={open}>
          <span ref={spanRef}>
            <ErrorIcon className={classes.svg} onClick={toggleTooltip} ref={svgRef} />
          </span>
        </StyledTooltip>
      </Hidden>
      <Hidden smDown>
        <StyledTooltip placement="top" title={title}>
          <span ref={spanRef}>
            <ErrorIcon className={classes.svg} ref={svgRef} />
          </span>
        </StyledTooltip>
      </Hidden>
    </>
  );
};

export default RetailErrorTooltip;

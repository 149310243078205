import React from 'react';
import { Divider, DividerProps, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => {
  return {
    bottomDivider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      backgroundColor: '#D3D5DC',
    },
  };
});

const RetailDivider: React.FC<DividerProps> = (props) => {
  const classes = useStyles();

  return <Divider {...props} className={classes.bottomDivider} />;
};

export default RetailDivider;

import React, { FC } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

interface RetailTextBlock {
  list: Array<{ title: string; text: string }>;
}

const useStyle = makeStyles((theme: Theme) => ({
  descriptionBlock: {
    background: theme.palette.secondary.main,
    padding: theme.spacing(10, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(14, 0),
    },
  },
  text: {
    color: theme.palette.background.default,
    position: 'relative',
  },
  line: {
    borderLeft: `5px solid ${theme.palette.customColors.light}`,
    height: 29,
    position: 'absolute',
    top: 0,
    [theme.breakpoints.up('sm')]: {
      height: 47,
      top: -3,
    },
  },
}));

const RetailTextBlock: FC<RetailTextBlock> = (props) => {
  const { list } = props;
  const classes = useStyle();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Box className={classes.descriptionBlock} mb={isSmall ? 10 : 16}>
      <Container maxWidth={false} fixed>
        <Grid container direction="row" spacing={6}>
          {list.map((listItem, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Typography variant="h3" color={'textPrimary'} className={classes.text}>
                <Box className={classes.line} />
                <Box ml={4}>{listItem.title}</Box>
              </Typography>
              <Box mb={2} />
              <Typography variant="body1" className={classes.text}>
                {listItem.text}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default RetailTextBlock;

import { withStyles } from '@material-ui/core/styles';
import { PaginationItem } from '@material-ui/lab';
import { createStyles } from '@material-ui/core';

const RetailPaginationItem = withStyles(() =>
  createStyles({
    root: {
      borderRadius: 6,
      color: '#ABAFB5',
      fontWeight: 'bold',
    },
    page: {
      '&$selected': {
        backgroundColor: '#ABAFB5',
        color: '#fff',
      },
    },
    selected: {},
    icon: {
      fontSize: '1.8rem',
    },
  })
)(PaginationItem);

export default RetailPaginationItem;

import React from 'react';
import MaskedInput from 'react-text-mask';

interface RetailPhoneFormatProps {
  inputRef: (instance: MaskedInput | null) => void;
}

const RetailPhoneFormat: React.FC<RetailPhoneFormatProps> = (props) => {
  const { inputRef, ...rest } = props;

  return (
    <MaskedInput
      {...rest}
      mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      guide={false}
    />
  );
};

export default RetailPhoneFormat;

import React, { FC, useEffect, useRef, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { CloseRoundIcon, QuestionGreyIcon } from 'assets/svgs';

interface RetailInputHintProps {
  title: string;
  label: string;
  position: {
    top: number;
    left: number;
  };
  imageUrl: string;
}

const useStyle = makeStyles((theme: Theme) => {
  return {
    wrapper: {
      [theme.breakpoints.up('sm')]: {
        position: 'relative',
      },
    },
    popup: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '95%',
      height: 'auto',
      [theme.breakpoints.up('sm')]: {
        position: 'absolute',
        top: (props: RetailInputHintProps) => props.position.top,
        left: (props: RetailInputHintProps) => props.position.left,
        width: 422,
        height: 622,
      },
      background: '#ffffff',
      color: '#000000',
      borderRadius: 6,
      padding: '14px 12px 12px',
      boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
      zIndex: 9999,
    },
    imageWrapper: {
      overflow: 'auto',
      [theme.breakpoints.up('sm')]: {
        overflow: 'hidden',
      },
    },
    popupTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 12,
      width: '100%',
      fontWeight: 'bold',
      fontSize: 18,
      lineHeight: '18px',
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      background: 'none',
      border: 'none',
      color: '#59606D',
      fontSize: 14,
      fontWeight: 600,
      cursor: 'pointer',
      marginTop: 6,
      marginLeft: 4,
      textDecoration: 'underline',
      outline: 'none',
      '& > svg': {
        marginRight: 8,
      },
    },
    buttonClose: {
      display: 'flex',
      alignItems: 'center',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      outline: 'none',
    },
  };
});

const RetailInputHint: FC<RetailInputHintProps> = (props) => {
  const classes = useStyle(props);
  const { title, label, imageUrl } = props;
  const [open, setOpen] = useState<boolean>(false);
  const wrapperRef = useRef(null);

  const onToggle = () => setOpen(!open);

  const onClickAway = (event) => {
    if (open && wrapperRef && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      onToggle();
    }
  };

  const onPressEsc = (event) => {
    if (event.code === 'Escape' && open) {
      onToggle();
    }
  };

  useEffect(() => {
    document.addEventListener('click', onClickAway);
    document.addEventListener('keydown', onPressEsc);

    return () => {
      document.removeEventListener('click', onClickAway);
      document.removeEventListener('keydown', onPressEsc);
    };
  });

  return (
    <div className={classes.wrapper} ref={wrapperRef}>
      <span className={classes.button} onClick={onToggle}>
        <QuestionGreyIcon />
        <span>{label}</span>
      </span>
      <div className={classes.popup} style={{ display: open ? 'block' : 'none' }}>
        <div className={classes.popupTitle}>
          <span>{title}</span>
          <span className={classes.buttonClose} onClick={onToggle}>
            <CloseRoundIcon />
          </span>
        </div>
        <div className={classes.imageWrapper}>
          <img src={imageUrl} alt="" />
        </div>
      </div>
    </div>
  );
};

export default RetailInputHint;

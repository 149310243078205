import React, { FC } from 'react';
import { InputBaseProps, MenuItem, TextField, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

interface RetailTextFieldProps {
  id?: string;
  type?: string;
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  multiline?: boolean;
  maxCount?: number;
  selectItems?: string[];
  fullWidth?: boolean;
  inputStyles?: { [key: string]: string | number };
  InputProps?: InputBaseProps;
  error?: boolean;
  helperText?: string;
  minValue?: number;
  reverse?: boolean;
  isWhiteMode?: boolean;
  className?: string;
  classNameRoot?: string;
  newEnergy5?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    position: 'relative',
    background: ({ reverse }: RetailTextFieldProps) => (reverse ? '#30373F' : '#fff'),
    '& p': {
      position: 'absolute',
      bottom: 0,
      right: 2,
      padding: 4,
      margin: 0,
    },
  },
  newRoot: {
    background: '#FAFAFA !important',
    borderRadius: 5,
  },
  count: {
    fontSize: 12,
    color: '#ABAFB5',
    fontWeight: 600,
  },
  rootInput: {
    borderRadius: 15,
    '& ::placeholder': {
      color: '#abafb5',
      fontWeight: 600,
    },
    '& ::-webkit-input-placeholder ': {
      color: '#999',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #323B45',
      borderRadius: 6,
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
        transition: 'all 0.4s',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: 1,
      },
      '& .MuiInputBase-input': {
        color: ({ isWhiteMode }: RetailTextFieldProps) => (isWhiteMode ? '#fff' : '#30373F'),
      },
      '& .MuiInputBase-input .MuiOutlinedInput-input': {
        color: '#abafb5',
      },
    },
  },
  newRootInput: {
    borderRadius: 6,
    '& ::placeholder': {
      color: '#666',
      fontWeight: 600,
    },
    '& ::-webkit-input-placeholder ': {
      color: '#666',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #F0F2F7',
      borderRadius: 5,
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#F0F2F7',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#F0F2F7',
      },
    },
  },
}));

const RetailTextField: FC<RetailTextFieldProps> = ({
  type,
  placeholder,
  onChange,
  id,
  multiline = false,
  maxCount,
  value,
  selectItems = [],
  fullWidth = true,
  inputStyles,
  error = false,
  helperText,
  InputProps,
  reverse = false,
  isWhiteMode = false,
  className,
  classNameRoot,
  newEnergy5,
}) => {
  const styles = useStyles({ reverse, isWhiteMode });
  const isSelect = !!selectItems.length;
  return (
    <div className={clsx(styles.root, newEnergy5 && styles.newRoot, classNameRoot)}>
      <TextField
        classes={{ root: clsx(styles.rootInput, newEnergy5 && styles.newRootInput, className) }}
        error={error}
        helperText={helperText}
        type={type}
        select={isSelect}
        fullWidth={fullWidth}
        variant="outlined"
        placeholder={placeholder}
        onChange={(e) => onChange(String(e.target.value))}
        multiline={multiline}
        value={value}
        rows={5}
        InputProps={InputProps}
        inputProps={{
          maxLength: maxCount ? maxCount : null,
          style: inputStyles ? inputStyles : null,
          id,
        }}
      >
        {!!selectItems.length &&
          selectItems.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
      </TextField>
      {maxCount && (
        <Typography className={styles.count} align={'right'}>
          {value.split('').length} / {maxCount}
        </Typography>
      )}
    </div>
  );
};

export default RetailTextField;

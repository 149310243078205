import React, { FC } from 'react';
import {
  BaseTextFieldProps,
  FilledInputProps,
  InputProps,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { RetailErrorTooltip } from 'components/retail/structures';

interface RetailTextInputProps extends BaseTextFieldProps {
  spaces?: boolean;
  errorSpace?: boolean;
  formatter?: (value: any) => string;
  inputProps?: InputProps['inputProps'];
  InputProps?: Partial<FilledInputProps>;
  onChange: (value: any) => void;
  onFocus?: React.FocusEventHandler;
  onBlur?: React.FocusEventHandler;
  inputPrefix?: string;
  errorMessage?: string;
  type?: string;
  highlighted?: boolean;
}

const StyledTextInput = withStyles(() => ({
  root: {
    flexGrow: 1,
    border: 'none',
    backgroundColor: 'white',
  },
}))(TextField);

const useStyle = makeStyles((theme: Theme) => {
  return {
    wrapper: {
      display: 'inline',
      position: 'relative',
    },
    inputPrefix: {
      position: 'absolute',
      right: 16,
      top: '160%',
      fontWeight: 600,
      color: theme.palette.secondary.light,
      fontSize: 16,
    },
    errorIcon: {
      position: 'absolute',
      top: 21,
      right: 18,
      height: 19,
    },
    errorIconSpace: {
      top: 8,
    },
    withError: {
      '& input': {
        paddingRight: 45,
      },
    },
    highlighted: {
      border: `solid 1px ${theme.palette.primary.main} !important`,
      borderRadius: 6,
      transition: 'all 0.4s',
      boxShadow: '0 3px 6px 0 rgba(249, 194, 6, 0.2)',
    },
    focused: {
      border: `solid 1px ${theme.palette.primary.main} !important`,
      borderRadius: 6,
      transition: 'all 0.4s',
    },
  };
});

const RetailTextInput: FC<RetailTextInputProps> = ({
  spaces = false,
  InputProps,
  InputLabelProps,
  onChange,
  errorMessage,
  errorSpace,
  inputPrefix,
  highlighted = false,
  ...props
}) => {
  const classes = useStyle();

  return (
    <div className={classes.wrapper}>
      <StyledTextInput
        {...props}
        fullWidth
        margin={spaces ? 'dense' : 'none'}
        variant="filled"
        autoComplete="off"
        InputProps={{
          ...InputProps,
          disableUnderline: true,
          classes: {
            root: highlighted && classes.highlighted,
            focused: classes.focused,
          },
        }}
        InputLabelProps={{ ...InputLabelProps, shrink: true }}
        onChange={(e) => onChange(e.target.value)}
        className={clsx(errorMessage ? classes.withError : '')}
      />
      {inputPrefix && <div className={classes.inputPrefix}>{inputPrefix}</div>}
      {!!errorMessage && (
        <div className={clsx(classes.errorIcon, errorSpace ? classes.errorIconSpace : '')}>
          <RetailErrorTooltip title={errorMessage} />
        </div>
      )}
    </div>
  );
};

export default RetailTextInput;

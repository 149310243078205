import React from 'react';
import { Typography } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';
import { createStyles, WithStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { CloseModal } from 'assets/svgs';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2.25),
      backgroundColor: '#f7f8fa',
    },
    closeButton: {
      position: 'absolute',
      right: 6,
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    typography: {
      fontSize: 18,
      fontWeight: 600,
    },
  });

export interface UesDialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const RetailDialogTitle = withStyles(styles)((props: UesDialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5" className={classes.typography}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseModal />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default RetailDialogTitle;
